import {
  useInfiniteQuery,
  UseInfiniteQueryOptions as _UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQueries,
  useQuery,
  UseQueryOptions as _UseQueryOptions,
  UseQueryResult,
  QueryClient,
} from '@tanstack/react-query'
import {hasOwnProperty} from 'quickstart/utils'
import * as R from 'rambdax'
import {
  admin,
  api,
  ApiError,
  Falsy,
  IS_BROWSER,
  IS_CHROMATIC,
  logger,
  SITE_SUFFIX,
} from 'tizra'
import {TizraClient} from 'tizra/client'
import {navigationType, uptimeSeconds} from 'tizra/dom'
import {AsyncReturnType, JsonValue} from 'type-fest'
import {useHacks} from './useHack'
import {useHydrating} from './useHydration'

const log = logger('useApi')

/**
 * React hooks for calling the Tizra API with useQuery.
 */
export const useApi = makeUseApi(api, false)
export const useInfiniteApi = makeUseApi(api, true)
export const useAdminApi = makeUseApi(admin, false)
export const useInfiniteAdminApi = makeUseApi(admin, true)
export const useApis = makeUseApis(api)
export const useAdminApis = makeUseApis(admin)

type Params<K extends keyof TizraClient> = Parameters<TizraClient[K]>[0]

type UseQueryOptions<K extends keyof TizraClient, INF extends boolean = false> =
  INF extends true ?
    _UseInfiniteQueryOptions<AsyncReturnType<TizraClient[K]>, ApiError>
  : _UseQueryOptions<AsyncReturnType<TizraClient[K]>, ApiError>

export type UseApiOptions<
  K extends keyof TizraClient,
  INF extends boolean = false,
> = Partial<UseQueryOptions<K, INF>> & {
  alertOnError?: boolean | ((e: ApiError) => boolean)
  enableDuringHydration?: boolean
}

type UseApiReturnType<
  K extends keyof TizraClient,
  INF extends boolean = false,
> =
  INF extends true ?
    Exclude<
      UseInfiniteQueryResult<
        {pages: AsyncReturnType<TizraClient[K]>[]; pageParams: unknown[]},
        ApiError
      >,
      undefined
    >
  : Exclude<
      UseQueryResult<AsyncReturnType<TizraClient[K]>, ApiError>,
      undefined
    >

export type UseApi<INF extends boolean = false> = {
  [K in keyof TizraClient]: Params<K> extends string ?
    (
      firstArg: string | [string] | [string, JsonValue] | Falsy,
      options?: UseApiOptions<K, INF>,
    ) => UseApiReturnType<K, INF>
  : Params<K> extends (
    object // i.e. required
  ) ?
    (
      params: Params<K> | Falsy,
      options?: UseApiOptions<K, INF>,
    ) => UseApiReturnType<K, INF>
  : (
      params?: Params<K> | Falsy | true,
      options?: UseApiOptions<K, INF>,
    ) => UseApiReturnType<K, INF>
}

let alerted = false

const handleApiError = ({
  alert,
  error,
  name,
  reload,
}: {
  alert: boolean
  error: ApiError
  name: keyof TizraClient
  reload: boolean
}) => {
  log.error(error)
  if (!IS_BROWSER) return
  if (!error.status) return // ignore network errors, for now
  if (
    SITE_SUFFIX !== 'admin' &&
    reload &&
    error.status === 403 &&
    (name === 'loggedInUsers' || name === 'search' || name === 'searchTypes') &&
    (navigationType() !== 'reload' || uptimeSeconds() > 30)
  ) {
    window.location.reload()
  } else if (alert && !alerted) {
    alerted = true
    // Short delay to prevent alert from blocking reload.
    setTimeout(() => IS_CHROMATIC || window.alert(error), 1000)
  }
}

const hydratingResultBase = {
  status: 'pending',
  isPending: true,
  isSuccess: false,
  isError: false,
  isLoadingError: false,
  isRefetchError: false,
  data: undefined,
  dataUpdatedAt: 0,
  error: null,
  errorUpdatedAt: 0,
  errorUpdateCount: 0,
  isStale: false,
  isPlaceholderData: false,
  isFetched: false,
  isFetchedAfterMount: false,
  fetchStatus: 'paused',
  isFetching: false,
  isPaused: true,
  isRefetching: false,
  isLoading: false,
  isInitialLoading: false,
  failureCount: 0,
  failureReason: null,
} as const

const hydratingResult = <K extends keyof TizraClient>(
  actual: UseApiReturnType<K>,
): UseApiReturnType<K> => ({
  ...hydratingResultBase,
  refetch: async () => {
    log.error('someone called hydratingResult.refetch()')
    return hydratingResult(actual)
  },
})

const infiniteHydratingResult = <K extends keyof TizraClient>(
  actual: UseApiReturnType<any, true>,
): UseApiReturnType<K, true> => ({
  ...hydratingResultBase,
  data: {pages: [], pageParams: actual.data?.pageParams ?? []} as any,
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  fetchNextPage: async () => {
    log.error('someone called infiniteHydratingResult.fetchNextPage()')
    return infiniteHydratingResult(actual)
  },
  fetchPreviousPage: async () => {
    log.error('someone called infiniteHydratingResult.fetchPreviousPage()')
    return infiniteHydratingResult(actual)
  },
  hasNextPage: false,
  hasPreviousPage: false,
  refetch: async () => {
    log.error('someone called infiniteHydratingResult.refetch()')
    return infiniteHydratingResult(actual)
  },
})

/**
 * Make a useApi hook for accessing the Tizra API. This is not exported, because
 * it's only used to define the hooks statically above: useApi, useInfiniteApi,
 * useAdminApi, useInfiniteAdminApi.
 */
function makeUseApi<INF extends boolean>(
  api: TizraClient,
  infinite: INF,
): UseApi<INF> {
  return R.fromPairs(
    R.keys(api).map(<K extends keyof TizraClient>(name: K) => [
      name,
      (...queryArgs: QueryArgs<K, INF>): UseApiReturnType<K, INF> => {
        const {alertOnError, enableDuringHydration} = queryArgs[1] || {}
        const hydrating = useHydrating()
        const params = makeQueryParams<K, INF>({name, api, infinite, queryArgs})
        const query =
          infinite ?
            useInfiniteQuery<any, ApiError>(params as any) // TODO: any
          : useQuery<any, ApiError>(params)
        const hacks = useHacks()
        if (query.error) {
          handleApiError({
            alert:
              typeof alertOnError === 'function' ?
                alertOnError(query.error)
              : alertOnError ?? hacks.alert,
            error: query.error,
            name,
            reload: hacks.reloadOnApiError,
          })
        }
        return (
          !hydrating || enableDuringHydration ? query
          : infinite ?
            infiniteHydratingResult(query as any) // TODO: any
          : hydratingResult(query)) as UseApiReturnType<K, INF>
      },
    ]),
  ) as UseApi<INF>
}

/**
 * Make a useApis hook for accessing the Tizra API. This is not exported, because
 * it's only used to define the hooks statically above: useApis and useAdminApis.
 */
function makeUseApis(api: TizraClient) {
  return (queriesArgs: Array<[keyof TizraClient, ...any]>) => {
    const hydrating = useHydrating()
    const queries = useQueries<any[], UseQueryResult<unknown, ApiError>[]>({
      queries: queriesArgs.map(([name, ...queryArgs]) =>
        makeQueryParams({
          name,
          api,
          infinite: false,
          queryArgs: queryArgs as any,
        }),
      ),
    })
    const hacks = useHacks()
    queries.forEach((query, i) => {
      if (query.error) {
        const name = queriesArgs[i][0]
        const {alertOnError} = (queriesArgs[i][2] || {}) as UseApiOptions<
          any,
          false
        >
        handleApiError({
          alert:
            typeof alertOnError === 'function' ?
              alertOnError(query.error)
            : alertOnError ?? hacks.alert,
          error: query.error,
          name,
          reload: hacks.reloadOnApiError,
        })
      }
    })
    return !hydrating ? queries : (
        queries.map((q, i) => {
          const {enableDuringHydration} = (queriesArgs[i][2] ||
            {}) as UseApiOptions<any, false>
          return enableDuringHydration ? q : hydratingResult(q)
        })
      )
  }
}

type FirstArg<K extends keyof TizraClient> =
  | Falsy
  | (Params<K> extends undefined ? [true] : never)
  | (Params<K> extends string ? [Params<K>, Parameters<TizraClient[K]>[1]?]
    : Params<K>)

type SecondArg<
  K extends keyof TizraClient,
  INF extends boolean,
> = UseApiOptions<K, INF>

type QueryArgs<K extends keyof TizraClient, INF extends boolean> =
  | (Parameters<TizraClient[K]>[0] extends undefined ? [] : never)
  | [FirstArg<K>, SecondArg<K, INF>?]

type QK<K extends keyof TizraClient> =
  | (Params<K> extends undefined ? [K] : never)
  | (Params<K> extends string ? [K, Params<K>, Parameters<TizraClient[K]>[1]?]
    : [K, Params<K>])

function makeQueryParams<K extends keyof TizraClient, INF extends boolean>({
  name,
  api,
  infinite,
  queryArgs,
}: {
  name: K
  api: TizraClient
  infinite: INF
  queryArgs: QueryArgs<K, INF>
}): UseQueryOptions<K, INF> {
  const [firstArg, queryConfig] = queryArgs
  const call = api[name]

  // queryKey is only falsy if it was explicitly passed, not if it was
  // omitted. This is why we have to collect queryArgs before
  // destructuring, so that we can check the length.
  const queryKey: undefined | QK<K> =
    (
      queryArgs.length && !firstArg // undefined, null, false, '', 0
    ) ?
      undefined
      // At this point, undefined definitively means firstArg was
      // omitted, because of queryArgs.length check above.
    : firstArg === true || firstArg === undefined ? ([name] as QK<K>)
      // useApi.GET(['foo', {params}])
    : Array.isArray(firstArg) ? ([name, ...firstArg] as QK<K>)
      // useApi.query({tizraId: 'foo'})
    : ([name, firstArg] as QK<K>)

  // For useInfiniteQuery, start will be appended to the args, for example
  // useInfiniteApi.search(params, start) should integrate start into params.
  const queryFn: any =
    !queryKey ? undefined
    : infinite ?
      ({pageParam: start}: {pageParam: number}) =>
        typeof queryKey[1] === 'object' && start ?
          // @ts-ignore too complex
          call({...queryKey[1], start}, ...queryKey.slice(2))
          // @ts-expect-error spread argument complaints
        : call(...queryKey.slice(1))
      // @ts-expect-error spread argument complaints
    : () => call(...queryKey.slice(1))

  const config: Omit<UseQueryOptions<K, INF>, 'queryKey' | 'queryFn'> = {
    // react-query v2 changed from disabling by falsy key.
    // https://github.com/TanStack/query/releases/tag/v2.0.0
    enabled: !!queryKey,

    // react-query v5 changed to required initialPageParam.
    // https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#infinite-queries-now-need-a-initialpageparam
    ...(infinite && {getNextPageParam, initialPageParam: 0}),

    // queryConfig can be overridden by caller.
    ...(R.omit(['alert', 'enableDuringHydration'], queryConfig) as Omit<
      UseQueryOptions<K, INF>,
      'queryKey' | 'queryFn'
    >),
  }

  return {queryKey: queryKey as any, queryFn, ...config} as UseQueryOptions<
    K,
    INF
  >
}

function getNextPageParam(resp: unknown): number | undefined {
  if (
    resp &&
    typeof resp === 'object' &&
    hasOwnProperty(resp, 'next') &&
    typeof resp.next === 'string' &&
    hasOwnProperty(resp, 'size') &&
    typeof resp.size === 'number'
  ) {
    const queryString = resp.next.split('?', 2)[1] || ''
    const nextStart = parseInt(
      new URLSearchParams(queryString).get('start') || '',
      10,
    )
    if (typeof nextStart === 'number' && nextStart < resp.size) {
      return nextStart
    }
  }
}

export function makeWindowApi({queryClient}: {queryClient: QueryClient}) {
  return R.fromPairs(
    R.keys(api).map(<K extends keyof TizraClient>(name: K) => [
      name,
      (...queryArgs: QueryArgs<K, false>) => {
        const {enabled, ...params} = makeQueryParams<K, false>({
          name,
          api,
          infinite: false,
          queryArgs,
        })
        return queryClient.fetchQuery(params)
      },
    ]),
  ) as {[K in keyof TizraClient]: (...args: any[]) => Promise<any>}
}

export type WindowTizraApi = ReturnType<typeof makeWindowApi>
